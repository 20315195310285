import React from 'react'
import { Link } from 'gatsby'
import MenuLinks from '../../molecules/menu-links/'

const Menu = () => (
<div className="o-menu container">
  <div className="row menu d-flex align-items-center">
      <div className="col-8 col-md-4 col-lg-8">
          <h6><Link to="/">Tiago Santos</Link></h6>
      </div>
      <div className="col-md-8 col-lg-4 d-none d-sm-block">
        <MenuLinks />
      </div>
      <div className="col-4 o-menu__responsive">
        <button className="btn" type="button" data-toggle="collapse" data-target="#mobileMenu" aria-expanded="false" aria-controls="mobileMenu">
        <i className="fas fa-bars"></i></button>
      </div>
    </div>
    <div className="row collapse" id="mobileMenu">
      <div className="col-12" >
        <MenuLinks />
      </div>
    </div>
</div>
)

export default Menu
