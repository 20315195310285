import React from 'react'
import Icon from '../../../images/favicon.png'
import Metatags from '../../atoms/metatags'
import Menu from '../../organisms/menu/'
import Footer from '../../organisms/footer/'

const Page = (props) => {
    return (
      <>
        { props.meta ? (<Metatags
            title={props.meta.title}
            description={props.meta.description}
            thumbnail={props.meta.thumbnail ? props.meta.thumbnail : Icon}
            pathname={props.meta.slug}
        /> ) : null }
        
      <div className="t-page">
        <Menu />
        <div className="container t-page__content">{props.children}</div>
        <Footer />
      </div>
    </>
    )
}

export default Page
