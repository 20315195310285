
import React from 'react';
import Helmet from 'react-helmet'
import { graphql, StaticQuery } from 'gatsby'


const Metadata = (props) => (
    <StaticQuery
      query={query}
      render={({
        site: {
          siteMetadata: {
            title,
            description,
            trackingId
          },
        },
      }) => {
        const seo = {
          title: props.title || title,
          description: props.description || description,
          thumbnail: props.thumbnail,
          slug: props.slug,
          trackingId: trackingId,
          gaLink: "https://www.googletagmanager.com/gtag/js?id=" + trackingId
        }
        return (
          <>
         <Helmet
            title={seo.title}
            meta={[
                { name: 'title', content: seo.title },

                { name: 'description', content: seo.description },
                {
                    property: 'og:title',
                    content: seo.title,
                },
                {
                    property: 'og:url',
                    content: seo.url + seo.slug,
                },
                {
                    property: 'og:image',
                    content: seo.thumbnail && seo.thumbnail,
                },
                {
                    property: 'og:image:secure_url',
                    content: seo.thumbnail && seo.thumbnail,
                },
                {
                    property: 'og:description',
                    content: seo.description,
                },
                {
                    property: 'og:locale',
                    content: 'en',
                },
                { name: 'twitter:card', content: 'summary_large_image' },

                { name: 'twitter:title', content: seo.title },

                {
                    name: 'twitter:description',
                    content: seo.description,
                },
                {
                    name: 'twitter:image',
                    content: seo.thumbnail && seo.thumbnail,
                },
                { property: 'og:type', content: 'website' },
                { name: 'robots', content: 'index, follow' }
            ]}
        >
            <script async src={seo.gaLink}></script>
            <script>
                {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());

                    gtag('config', '${seo.trackingId}');
                `}
            </script>

            <html lang="en" />
        </Helmet>
          </>
        )
      }}
    />
  )

  export default Metadata

  const query = graphql`
    query SEO {
      site {
        siteMetadata {
          title
          description
          trackingId
        }
      }
    }
  `