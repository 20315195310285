import React from 'react'
import { Link } from 'gatsby'
// Utilities
import kebabCase from 'lodash/kebabCase'

const Tags = (props) => {
  return (
    <div className={ props.simple ? 'm-tags simple' : 'm-tags' }>
      <ul>
        {props.tags.map((tag, i) => (
          <li key={i}>
            <Link to={`/tags/${kebabCase(tag)}/`}>
              {tag}
            </Link>
          </li>
        ))}
    </ul>
    </div>
  );
}

export default Tags
