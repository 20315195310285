import React from 'react'
import { Link } from 'gatsby'

const MenuLinks = () => {
    return(
    <ul className="m-menu-links">
        <li><h6><Link to="/articles">Articles</Link></h6></li>
        <li><h6><Link to="/publications">Publications</Link></h6></li>
    </ul>
)}

export default MenuLinks
