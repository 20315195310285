import React from 'react'

const PostInfo = (props) => {
    return (
          <div className="m-post-info">
            {props.date ? (
                <div>
                <i className="fas fa-calendar"></i> {props.date}{' '}
                </div>
            ) : null}
            {props.time ? (
                <div>
                <i className="fas fa-clock"></i> {props.time}
                {' min read'}
                </div>
            ) : null}
          </div>
      )
}

export default PostInfo