import React from 'react'
import PostLink from '../../organisms/post-link/'

const ArticleList = props => {
  return (
        <div className="o-article-list">
            {props.posts.map(( node , i) => (
            <div className="o-post-link" key={i}>
                <PostLink
                    tags={node.frontmatter.tags}
                    slug={node.fields.slug}
                    title={node.frontmatter.title}
                    date={node.frontmatter.date}
                    time={node.timeToRead}
                    url={node.frontmatter.url}
                    class={i === 0 && props.posts.length > 1 ? 'first' : ''}
                />
            </div>
            ))}
        </div>
  )
}

export default ArticleList