import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

export default () => (
  <StaticQuery
    query={graphql`
      query ContactsQuery {
        site {
          siteMetadata {
            twitter
            linkedin
            github
            stackoverflow
            dribbble
            researchgate
            rss
          }
        }
      }
    `}
    render={data => (
      <footer className="o-footer container">
        <div className="row">
        <div className="col-md-8 o-footer__social">
          <h6>Find me here</h6>
          <ul>
            <li>
              <a
                href={data.site.siteMetadata.linkedin}
                target="_blank"
                rel="noopener noreferrer"
              >
              <i className="fab fa-linkedin"></i>
              </a>
            </li>
            <li>
              <a
                href={data.site.siteMetadata.researchgate}
                target="_blank"
                rel="noopener noreferrer"
              >
              <i className="fab fa-researchgate"></i>
              </a>
            </li>
            <li>
              <a
                href={data.site.siteMetadata.github}
                target="_blank"
                rel="noopener noreferrer"
              >
              <i className="fab fa-github"></i>
              </a>
            </li>
            <li>
              <a
                href={data.site.siteMetadata.stackoverflow}
                target="_blank"
                rel="noopener noreferrer"
              >
              <i className="fab fa-stack-overflow"></i>
              </a>
            </li>
            <li>
              <a
                href={data.site.siteMetadata.dribbble}
                target="_blank"
                rel="noopener noreferrer"
              >
              <i className="fab fa-dribbble"></i>
              </a>
            </li>
          </ul>
        </div>
        {/* <div className="footer-rss col-md-4">
          <h6>RSS feed</h6>
          <a
            href={data.site.siteMetadata.dribbble}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaRss />
          </a>
        </div> */}
        </div>
      </footer>
    )}
  />
)
