import React from 'react'
import { Link } from 'gatsby'
import Tags from '../../atoms/tags/'
import PostInfo from '../../molecules/post-info/'

const PostLink = (props) => {
    return (
        <div className={props.class ? props.class : ''}>
          { props.tags ? (
            <Tags tags={props.tags} simple={true} />
          ) : null }

          {props.url ? (
            <h4><a href={props.url} target="_blank">{props.title}</a><i className="fas fa-external-link-alt"></i></h4>
          ) : (
            <h4><Link to={props.slug}>
              {props.title}
            </Link></h4>
          )}
          <div className="o-post-link__info">
            <PostInfo date={props.date} time={props.time}/>
          </div>
        </div>
      )
}

export default PostLink